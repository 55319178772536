import React, {useEffect, useState} from 'react';
import Sidebar from './sidebar';
import Axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'
import { Button } from '@material-ui/core';



export default function MainBody(props) {

  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);

  
  const URL = "/api/list"
 

  useEffect(() => {
    const fetchData = async () => {

      var url = URL+"/2025";
  
      if (props.annee !== undefined)
      {
          url= URL+"/"+props.annee;
      }
   
       await 
        new Promise((resolve, reject) => {

          var config = {
          };
          Axios.get(url, config)
            .then(function(response) {
              setItems(response.data);
              setLoading(false);
            })
            .catch(function(error) {
              console.log(error);
            });
        });
       };
       fetchData();
      },[props.annee]);

      

  

if (loading) {
  return <CircularProgress/>
}



  return (
    <div id="wrapper">

      {/*  Main */}



      <div id="main">

        {items && items.map((item) =>
         
          <article key={item.ID} className="post">
          <header>
            <div className="title">
             <h2><Link component={RouterLink} to={"/detail/"+item.ID}>{item.Title}</Link></h2>
              <p>{item.SubTitle}</p>
            </div>
            <div className="meta">
              <time className="published" dateTime={item.EventDate}>{item.DisplayDate}</time>
              <span className="name">{item.Organiser}</span>
              {item.AssociationLink !==""  ?
              <span className="name"><a target="_blank" rel="noopener noreferrer" href={item.AssociationLink}>{item.Association}</a></span>
              : <span className="name">{item.Association}</span>
            }
            </div>
          </header>
          <ul className="actions">
              <li><Button color="secondary" size="large" className="icon solid fa-info-circle">&nbsp;<Link component={RouterLink} to={"/detail/"+item.ID}>Plus d'information...</Link></Button></li>
              {item.hasGallery &&
              <li><Button color="secondary" size="large" className="icon solid fa-photo-video">&nbsp;
                 <a href={`https://photos.app.goo.gl/${item.GalleryCode}`} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                  Galerie d'image...
                 </a>
              </Button></li>
              }
            </ul>
          {item.Img &&
          <Link component={RouterLink} to={"/detail/"+item.ID} className="image featured"><img src={"/api/media/"+item.Img} alt={item.Img} /></Link>
         }
          <div dangerouslySetInnerHTML={{__html: item.ShortDescription}} /> 
          {item.Img &&
          <footer>
            <ul className="actions">
              <li><Button color="secondary" size="large" className="icon solid fa-info-circle">&nbsp;<Link component={RouterLink} to={"/detail/"+item.ID}>Plus d'information...</Link></Button></li>
              {item.hasGallery &&
              <li><Button color="secondary" size="large" className="icon solid fa-photo-video">&nbsp;
                 <a href={`https://photos.app.goo.gl/${item.GalleryCode}`} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                  Galerie d'image...
                 </a>
               </Button></li>
              }
            </ul>
          </footer>
        }
        </article>
         
        )}
        
     
      </div>
        <Sidebar></Sidebar>

        

    </div>

  );


}
